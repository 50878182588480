@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://fonts.googleapis.com/css?family=Raleway:400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import './assets/icofont.min.css';

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.topbar-btn-amministrazione {
  background-color: #e0e0e0;
  color: #655c5c;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  text-decoration: none;
  padding: 3px;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
}

.topbar-btn-amministrazione:hover {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  background-color: #ff9800;
  color: #fff;
}

.topbar-lnk-social {
  display: flex;
  height: 30px;
  width: 30px;
  padding: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #90a4ae;
  color: #fff;
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.topbar-lnk-social:hover {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  background-color: #ff9800;
}

.lnk-menu {
  display: flex;
  position: relative;
  width: 120px;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  padding: 11px;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none;
  color: #fff;
  align-items: center;
  font-weight: 700;
}

.lnk-menu:hover {
  content: '';
  display: block;
  border-bottom: 5px solid #455a64;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.base-transition {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #9e9e9e;

  /* Change the color as desired */
}

.btn-orange {
  background-image: linear-gradient(to right, #ffb74d 50%, #ff9800 50%);
  background-size: 200% auto;
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
}

.btn-orange:hover {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  background-position: -99.99% 0;
}

.btn-red {
  background-image: linear-gradient(to right, #f57a71 50%, #f44336 50%);
  background-size: 200% auto;
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
}

.btn-red:hover {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  background-position: -99.99% 0;
}

.btn-green {
  background-image: linear-gradient(to right, #66BB6A 50%, #2E7D32 50%);
  background-size: 200% auto;
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
}

.btn-green:hover {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  background-position: -99.99% 0;
}

.btn-grey {
  background-image: linear-gradient(to right, #BDBDBD 50%, #424242 50%);
  background-size: 200% auto;
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
}

.btn-grey:hover {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  background-position: -99.99% 0;
}

.servizi-icon {
  display: flex;
  width: 100px;
  height: 100px;
  border: 3px solid #ff9800;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ff9800;
  flex-shrink: 0;
  font-size: 50px;
  justify-content: center;
  align-items: center;
}

.lnk-gen {
  color: #655c5c;
  border-bottom: 1px solid #ff9800;
  text-decoration: none;
  background-image: linear-gradient(to right, #fff 50%, #ff9800 50%);
  background-position: -0% 0;
  background-size: 200% auto;
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  padding: 2px 4px;
}

.lnk-gen:hover {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  color: #fff;
  background-position: -99.99% 0;
}

.privacy-cnt {
  position: absolute;
  top: 0;
  z-index: 1111;
  background-color: rgba(0, 0, 0, .7);
  width: 100%;
  padding: 5px 0 5px 15px;
  color: #fff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #ff9800;
  /* Change color as needed */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.select {
  height: 35px;
  width: 100%;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.select2 {
  height: 35px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.input-text {
  height: 35px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.add-building-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  z-index: 1000;
}

.add-building-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1001;
  max-height: 80%;
  width: 1200px;
  /* Altezza massima del popup */
  overflow-y: auto;
  /* Aggiunge uno scroll verticale se necessario */
}

.add-building-popup-lbl {
  display: block;
  font-size: 10px;
  margin-bottom: 3px;
  text-transform: uppercase;
  color: #655c5c;
  font-family: 'Raleway', sans-serif;
}

.react-datepicker-wrapper {
  width: 100%;
}

.full-width-datepicker {
  width: 100%;
}

.width-95-datepicker {
  width: 95%;
}

.text-area {
  height: auto;
}

.overlay2 {
  background-color: rgba(0, 0, 0, 0.8);
}

.description-result {
  position: relative;
  max-height: 100px;
  overflow: hidden;
}

.description-result::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100px;
  z-index: 1;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #fff 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #fff 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
}

.search-label-locazione {
  position: absolute;
  top: 100px;
  left: 0;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  background-color: #ff9800;
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  z-index: 100;
}

.search-label-vendita {
  position: absolute;
  top: 100px;
  left: 0;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  background-color: #f44336;
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  z-index: 100;
}

.search-item {
  content: '';
}

.search-item:hover img {
  filter: grayscale(100%);
}

.no-style-link {
  color: blue;
  text-decoration: underline;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(158, 158, 158, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 2000;
}

.lightbox img {
  cursor: pointer;
  max-width: 90%;
  max-height: 90%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.lightbox i {
  position: fixed;
  top: 50%;
  cursor: pointer;
  z-index: 3000;
  font-size: 40px;
  color: #fff;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  text-shadow: 1px 1px #9E9E9E;
}

.lightbox .fa-arrow-circle-o-left {
  left: 10px;
}

.lightbox .fa-arrow-circle-o-right {
  right: 10px;
}

.lightbox .fa-times-circle-o {
  top: 10px;
  right: 10px;
}

.img-search {
  width: 100%;
  height: 145px;
  object-fit: cover;
}